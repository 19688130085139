<template>
  <el-table
    ref="styledTable"
    :data="data"
    v-bind="$attrs"
    v-on="$listeners"
    @row-click="rowClicked"
    header-cell-class-name="table-header"
    style="width: 100%; border-spacing: 0 1em"
    :row-class-name="rowClassName"
  >
    <slot></slot>
  </el-table>
</template>

<script>
export default {
  inheritAttrs: true,
  props: {
    data: {
      required: true,
    },
  },
  methods: {
    rowClicked(row) {
      this.$emit("rowClicked", row);
    },
    rowClassName(row) {
      let style = ["table-row"];
      if (this.$attrs["row-class-name"])
        style.push(this.$attrs["row-class-name"](row));
      return style.join(" ");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table-header {
  color: #8ea6c1;
}
::v-deep .table-row {
  font-weight: 900;
  color: #405793;
  i {
    color: #fccd13;
    transition: all 0.25s ease-in-out;
    &:hover {
      opacity: 0.4;
    }
  }
  cursor: pointer;
  background-color: #ffffff;
  margin-bottom: 1rem;
  border-radius: 1px;
  box-shadow: 0 0 32px 0 rgba(12, 10, 61, 0.04);
  .small-text {
    font-weight: 500;
    color: #abb5d0;
  }
}
</style>
