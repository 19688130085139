<template>
  <div>
    <!-- ------------------------------------------------- -->
    <!-- Filters Area -->
    <!-- ------------------------------------------------- -->
    <MetricFilterBox
      :defaultFilterData="metricDefaultData"
      v-model="metricsList"
      @clearFilters="clearFilters"
      @openMetric="saveMetircs"
      :clearBtnShowen="true"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            v-if="metric.key == 'hasSecondPhone'"
            :is="metric.compName"
            v-model="filters[metric.key]"
            :state="metric.displayedData ? metric.displayedData : filters.state"
            @change="filtersChanged(metric.key, $event)"
            @enterClicked="filterEnterClicked(metric.key, filters[metric.key])"
            :meta="metric.meta ? metric.meta : null"
          ></component>
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            @change="filtersChanged(metric.key, $event)"
            :meta="metric.meta ? metric.meta : {}"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>

    <!-- ------------------------------------------------- -->
    <!-- Bulk Operations Area -->
    <!-- ------------------------------------------------- -->
    <RetailerSheetsHandler
      :chosenFilters="filters"
      @dataUpdated="refetchData"
    />

    <!-- ------------------------------------------------- -->
    <!-- bulk Edit Area -->
    <!-- ------------------------------------------------- -->
    <el-collapse-transition>
      <el-card v-show="selectedRetailers.length" class="edit-view">
        <el-form
          :model="bulkEditData"
          ref="retailersForm"
          @submit.native.prevent="updateRetailers"
          :rules="bulkEditRetailersRules"
          :inline="true"
          size="normal"
        >
          <div class="u-bottom-margin--2x">
            <el-button type="primary" size="mini" native-type="submit"
              >{{
                $t("RetialersPageBtnBulkUpdate", [selectedRetailers.length])
              }}
            </el-button>
          </div>
          <el-form-item prop="storeName" :label="$t('RetailersPageInputName')">
            <el-input v-model="bulkEditData.storeName"></el-input>
          </el-form-item>
          <el-form-item
            prop="agentCode"
            :label="$t('RetailersPageInputAgentCode')"
          >
            <el-input v-model="bulkEditData.agentCode"></el-input>
          </el-form-item>
          <el-form-item prop="address" :label="$t('RetailersPageInputAddress')">
            <el-input v-model="bulkEditData.address"></el-input>
          </el-form-item>
          <el-form-item :label="$t('RetailersPageInputArea')">
            <el-select
              v-model="bulkEditData.areaId"
              value-key=""
              placeholder=""
              clearable
              filterable
            >
              <el-option
                v-for="item in lookups.areas"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            prop="totalPoints"
            :label="$t('RetailersPageInputTotalPoints')"
          >
            <el-input v-model.number="bulkEditData.totalPoints"></el-input>
          </el-form-item>
          <el-form-item :label="$t('RetailersPageInputIsActive')" size="normal">
            <el-switch
              v-model="bulkEditData.active"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </el-form-item>

          <el-form-item
            prop="notes"
            :label="$t('RetailersPageInputNotes')"
            size="normal"
          >
            <el-input
              v-model="bulkEditData.notes"
              :active-value="1"
              :inactive-value="0"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-card>
    </el-collapse-transition>
    <div class="u-bottom-margin--2x">
      <el-button type="primary" size="default" @click="showEntityDialog = true"
        >Add New Entity</el-button
      >

      <create-entity-dialog
        :isDialogVisible="showEntityDialog"
        @entity-saved="addNewEntity"
        @dialog-closed="showEntityDialog = false"
      ></create-entity-dialog>
    </div>

    <!-- ------------------------------------------------- -->
    <!-- Table Area -->
    <!-- ------------------------------------------------- -->
    <styled-table
      ref="retailersTable"
      :data="retailersList"
      @row-click="viewRetailersDetails"
      @selection-change="BulkRetailersChanged"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <template v-for="col in retailerTableColumns">
        <el-table-column
          v-if="!col.hasSlot"
          :key="col.prop"
          :prop="col.prop"
          :label="$t(col.label)"
          :width="col.width"
        >
        </el-table-column>
        <el-table-column
          v-else-if="col.prop === 'location'"
          :key="col.prop"
          :prop="col.prop"
          :label="$t(col.label)"
          :width="col.width"
        >
          <template slot-scope="scope">
            <div class="location-container">
              <div class="location-region">
                {{ scope.row.region }}
              </div>
              <div class="location-area small-text">
                {{ scope.row.area }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="col.prop === 'productOffersPercentage'"
          :key="col.prop"
          :prop="col.prop"
          :label="$t(col.label)"
          :width="col.width"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.productOffersPercentage }} %</div>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="col.prop === 'cashbackCappingPercentage'"
          :key="col.prop"
          :prop="col.prop"
          :label="$t(col.label)"
          :width="col.width"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.cashbackCappingPercentage }} %</div>
          </template>
        </el-table-column>
      </template>
    </styled-table>
    <!-- ************************* -->
    <!-- pagination -->
    <!-- ************************ -->
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="pagination.totalResultsCount"
        :current-page="currentPage"
        :page-size="50"
        @current-change="handlePagination"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import styledTable from "@/components/dataContainers/styledTable.vue";
import CreateEntityDialog from "./CreateEntityDialog.vue";
import { retailersService } from "@/services";
import { entitiesServices } from "../../../services/entities.service";
import metricbox from "@/components/transaction/metricbox";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import inputMetric from "@/components/transaction/metricboxComponents/inputMetric.vue";
import areaMetric from "@/components/transaction/metricboxComponents/areaMetric.vue";
import regionMetric from "@/components/transaction/metricboxComponents/regionMetric.vue";
import dateMetric from "@/components/transaction/metricboxComponents/startDateMetric.vue";
// import radioMetric from "@/components/transaction/metricboxComponents/radioMetric.vue";
import dynamicTypeMetric from "@/components/transaction/metricboxComponents/dynamicTypeMetric.vue";
import extendedFunctions from "../../../utils/extendedFunctions";
import { mapState } from "vuex";
import _ from "lodash";
import RetailerSheetsHandler from "./RetailerSheetsHandler.vue";

export default {
  name: "RetailersPage",
  components: {
    styledTable,
    metricbox,
    MetricFilterBox,
    inputMetric,
    dateMetric,
    areaMetric,
    regionMetric,
    // radioMetric,
    dynamicTypeMetric,
    CreateEntityDialog,
    RetailerSheetsHandler,
  },
  data() {
    return {
      filters: {},
      retailersList: [],
      metricsList: [],
      metricDefaultData: [
        {
          key: "area",
          label: "RetailersPageFilterLabelArea",
          compName: "areaMetric",
        },
        {
          key: "region",
          label: "RetailersPageFilterLabelRegion",
          compName: "regionMetric",
        },
        {
          key: "id",
          label: "RetailersPageFilterLabelEntityId",
          compName: "inputMetric",
        },
        {
          key: "name",
          label: "RetailersPageFilterLabelEntityName",
          compName: "inputMetric",
        },
        {
          key: "agentCode",
          label: "RetailersPageFilterLabelEntityAgentCode",
          compName: "inputMetric",
        },
        {
          key: "totalPointsFrom",
          label: "RetailersPageFilterLabelTotalPointsFrom",
          compName: "inputMetric",
        },
        {
          key: "totalPointsTo",
          label: "RetailersPageFilterLabelTotalPointsTo",
          compName: "inputMetric",
        },
        {
          key: "createdAtFrom",
          label: "RetailersPageFilterLabelCreatedFrom",
          compName: "dateMetric",
        },
        {
          key: "createdAtTo",
          label: "RetailersPageFilterLabelCreatedTo",
          compName: "dateMetric",
        },
        {
          key: "countOfTransactionsFrom",
          label: "RetailersPageFilterLabelCountOfTransactionsFrom",
          compName: "inputMetric",
        },
        {
          key: "countOfTransactionsTo",
          label: "RetailersPageFilterLabelCountOfTransactionsTo",
          compName: "inputMetric",
        },
        {
          key: "sumOfTransactionsFrom",
          label: "RetailersPageFilterLabelSumOfTransactionsFrom",
          compName: "inputMetric",
        },
        {
          key: "sumOfTransactionsTo",
          label: "RetailersPageFilterLabelSumOfTransactionsTo",
          compName: "inputMetric",
        },
        {
          key: "userId",
          label: "RetailersPageFilterLabelUserId",
          compName: "inputMetric",
        },
        {
          key: "userName",
          label: "RetailersPageFilterLabelUserName",
          compName: "inputMetric",
        },
        {
          key: "userPhone",
          label: "RetailersPageFilterLabelUserPhone",
          compName: "inputMetric",
        },
        {
          key: "hasSecondPhone",
          label: "Has second phone",
          compName: "dynamicTypeMetric",
          displayedData: [
            {
              value: "Yes",
            },
            {
              value: "No",
            },
          ],
        },
        // {
        //   key: "hasSecondPhone",
        //   label: "Has second phone",
        //   compName: "radioMetric",
        //   meta: {
        //     radioItems: [
        //       {
        //         label: true,
        //         title: "Yes",
        //       },
        //       {
        //         label: false,
        //         title: "No",
        //       },
        //     ],
        //   },
        // },
      ],
      bulkEditRetailersRules: {
        storeName: [
          {
            min: 5,
            max: 70,
            message: "Store Name length should be 5 to 70",
            trigger: "change",
          },
        ],
        agentCode: [
          {
            min: 0,
            max: 50,
            message: "Agent code length should be 0 to 50",
            trigger: "change",
          },
        ],
        address: [
          {
            min: 10,
            max: 100,
            message: "Address length should be 10 to 100",
            trigger: "change",
          },
        ],
        totalPoints: [
          {
            type: "number",
            min: 0,
            max: 5000,
            message: "Points length should be 0 to 5000",
            trigger: "change",
          },
        ],
        notes: [
          {
            min: 0,
            max: 500,
            message: "Notes length should be 0 to 500",
            trigger: "change",
          },
        ],
      },
      pagination: {
        totalResultsCount: 0,
      },
      retailerTableColumns: [
        { prop: "id", label: "Entity Id", width: "80" },
        { prop: "name", label: "RetailersPageTableColumnName" },
        { prop: "entityType", label: "RetailersPageTableColumnEntityType" },
        { prop: "location", label: "Location", hasSlot: true },

        {
          prop: "countOfActiveUsers",
          width: "120",
          label: "RetailersPageTableColumnOfActiveUsers",
        },
        {
          prop: "totalCountOfTransactions",
          label: "# of Trx",
          width: "80",
        },
        { prop: "agentCode", label: "RetailersPageTableColumnAgentcode" },
        {
          prop: "totalPoints",
          label: "RetailersPageTableColumnTotalPoints",
          width: "80",
        },
        {
          prop: "sumOfCompletedTransactions",
          label: "Sum of Trx",
        },
        { prop: "created_at", label: "RetailersPageTableColumnCreatedAt" },
        {
          prop: "productOffersPercentage",
          label: "Product offer percentage",
          hasSlot: true,
        },
        {
          prop: "cashbackCappingPercentage",
          label: "Cashback Capping Percentage",
          hasSlot: true,
        },
      ],
      bulkEditData: {
        active: 1,
      },
      selectedRetailers: [],
      showEntityDialog: false,
    };
  },
  computed: {
    currentPage() {
      return this.$route.query.page
        ? Number.parseInt(this.$route.query.page)
        : 1;
    },
    ...mapState({
      lookups: (state) => state.Lookups.lookups,
    }),
  },
  created() {
    const metricsSession = JSON.parse(
      localStorage.getItem("entities-retailers-metrics")
    );
    if (metricsSession) {
      this.metricsList = metricsSession;
    }
    this.getRetailers(this.currentPage);
  },

  methods: {
    // ***************************************************
    // filters Area
    // ***************************************************
    filtersChanged(key, val) {
      if (val !== 0 && val !== false && _.isEmpty(val)) {
        this.filters[key] = undefined;
      }
      this.getRetailers(1);
      this.$router.push({
        query: {
          page: 1,
        },
      });
    },

    saveMetircs() {
      localStorage.setItem(
        "entities-retailers-metrics",
        JSON.stringify(this.metricsList)
      );
    },
    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      //add check if filter exist to avoid unnecessary api calls
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      //fix lodash reactivity issue
      this.metricsList = [...this.metricsList];
    },
    clearFilters() {
      this.filters = {};
      this.filtersChanged();
    },
    // ***************************************************
    // bulk Edit Area
    // ***************************************************
    BulkRetailersChanged(retailers) {
      this.selectedRetailers = retailers.map((retailer) => retailer.id);
    },
    async updateRetailers() {
      const valid = await this.$refs["retailersForm"].validate();
      if (!valid) return;
      let updatedRetailers = this.selectedRetailers.map((retailerId) => {
        return {
          entityId: retailerId,
          ...this.bulkEditData,
        };
      });

      await this.$confirm(
        this.$t("RetailersPagePrompMsg"),
        this.$t("RetailersPagePrompTitle"),
        {
          confirmButtonText: this.$t("GLobalsOk"),
          cancelButtonText: this.$t("GlobalCancelBtn"),
          type: "warning",
        }
      ).then(async () => {
        try {
          await retailersService.bulkUpdateRetailers(updatedRetailers);
          this.$refs.retailersTable.$refs.styledTable.clearSelection();
          await this.getRetailers(this.currentPage);
          this.$notify({
            title: "Success",
            message: "Retailers Updated successfully",
            type: "success",
          });
        } catch (error) {
          console.log(error);
          this.$notify({
            title: this.$t("GlobalsErrorTitle"),
            message: this.$t("GlobalErrorMessage"),
          });
        }
      });
    },
    // ***************************************************
    // Table Area
    // ***************************************************
    async getRetailers(page = 1) {
      try {
        let retailersFilters = Object.assign({}, this.filters);
        const retailersResponse = await retailersService.getRetailers(
          page,
          retailersFilters
        );
        this.retailersList = retailersResponse.retailersList;
        this.pagination = retailersResponse.pagination;
      } catch (error) {
        console.log(error);
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: this.$t("GlobalErrorMessage"),
        });
      }
    },

    viewRetailersDetails(row) {
      this.$router.push({
        name: "RetailersDetailsPage",
        params: { id: row.id },
      });
    },

    // ***************************************************
    // pagination Area
    // ***************************************************
    async handlePagination(newPage) {
      await this.getRetailers(newPage);
      this.$router.push({
        query: {
          page: newPage,
        },
      });
    },

    async addNewEntity(entity) {
      try {
        await entitiesServices.addNewEntity(entity);
        this.showEntityDialog = false;
        this.$notify({
          title: "Success",
          message: "New Retailer Created successfully",
          type: "success",
        });
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },
    refetchData() {
      this.getRetailers(this.currentPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-view {
  margin: 1rem 0;
  position: sticky;
  top: 0;
  z-index: 100;
}
main {
  * {
    word-break: break-word;
  }
}
::v-deep .location-area {
}
::v-deep .cell {
  word-break: break-word !important;
}
</style>
