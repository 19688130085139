import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function fetchEntities(page = 1, filters) {
  return $http.get(`/admin/entities`, {
    params: { page, filters },
    headers: authHeader(),
  });
}

function blackListUsers(data) {
  return $http.post(`/admin/entityBlacklists`, data, {
    headers: authHeader(),
  });
}

function deleteBlacklistedEntries(providerId, apiOpbject) {
  return $http.delete(`/admin/entityBlacklists/${providerId}`, {
    data: apiOpbject,
    headers: authHeader(),
  });
}

function getEntityWorkingHours(entityId) {
  return $http.get(`/admin/entity/${entityId}/working-hours`, {
    headers: authHeader(),
  });
}
function setEntityWorkingHours(entityId, workingHoursArray) {
  return $http.put(
    `/admin/entity/${entityId}/working-hours`,
    { workingHours: workingHoursArray },
    {
      headers: authHeader(),
    }
  );
}

function addNewUserProfileToEntity(entityId, data) {
  return $http.post(`/admin/retailer/${entityId}/user-profile`, data, {
    headers: authHeader(),
  });
}
function addNewEntity(entity) {
  return $http.post(
    "/admin/entity-assign",
    { user: entity },
    {
      headers: authHeader(),
    }
  );
}
export const entitiesServices = {
  fetchEntities,
  blackListUsers,
  deleteBlacklistedEntries,
  getEntityWorkingHours,
  setEntityWorkingHours,
  addNewUserProfileToEntity,
  addNewEntity,
};
