<template>
  <el-dialog
    title="Add new Entity"
    width="60%"
    @close="clearProfileDialog"
    :visible.sync="dialogVisible"
  >
    <el-form
      :model="entityDetails"
      ref="profileForm"
      :rules="profileRules"
      label-width="140px"
      :inline="false"
      size="normal"
    >
      <el-form-item label="User Name" prop="name">
        <el-input v-model="entityDetails.name"></el-input>
      </el-form-item>
      <el-form-item label="Store Name" prop="storeName">
        <el-input v-model="entityDetails.storeName"></el-input>
      </el-form-item>
      <el-form-item label="Phone Number" prop="phone">
        <el-input v-model="entityDetails.phone" type="number"></el-input>
      </el-form-item>
      <el-form-item label="entity Password" prop="password">
        <el-input v-model="entityDetails.password"></el-input>
      </el-form-item>
      <el-form-item label="entity address" prop="address">
        <el-input v-model="entityDetails.address"></el-input>
      </el-form-item>
      <el-form-item label="select region" prop="regionId">
        <el-select
          v-model="entityDetails.regionId"
          value-key=""
          placeholder=""
          clearable
          filterable
        >
          <el-option
            v-for="item in allRegions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Entity Type" size="normal" prop="entity">
        <!-- @change="getChannelTypes" -->
        <el-select v-model="entityDetails.entity" filterable>
          <el-option
            v-for="item in lookups.entity_types.filter(
              (type) => type.activity === 'consumes'
            )"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="Channel Type" size="normal" prop="channelType">
        <el-select v-model="entityDetails.channelTypeId" filterable>
          <el-option
            v-for="item in channelTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
    </el-form>

    <span slot="footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="saveProfile">OK</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapState } from "vuex";
// import { retailersService } from "../../../services";
// import extendedFunctions from "../../../utils/extendedFunctions";

export default {
  name: "entityDialog",
  props: { isDialogVisible: { type: Boolean, default: false } },
  data() {
    const mustBeginWith0 = (rule, value, callback) => {
      if (value[0] !== "0") {
        callback(new Error("Number must start with 0"));
      } else callback();
    };
    return {
      // channelTypeId: "",
      // channelTypes: [
      //   { id: "option1", label: "Option 1" },
      //   { id: "option2", label: "Option 2" },
      //   { id: "option3", label: "Option 3" },
      // ],
      entityDetails: {},
      profileRules: {
        name: [
          {
            required: true,
            message: "User name is required",
            trigger: "change",
          },
        ],
        storeName: [
          {
            required: true,
            message: "Store name is required",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "User phone is required",
            trigger: "change",
          },
          {
            min: 11,
            max: 11,
            message: "Number must be 11 digits only ",
            trigger: "change",
          },
          { validator: mustBeginWith0, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "password is required",
            trigger: "change",
          },
          {
            min: 6,

            message: "password must be at least 6 digits  ",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "address is required",
            trigger: "change",
          },
        ],
        regionId: [
          {
            required: true,
            message: "region is required",
            trigger: "change",
          },
        ],
        entity: [
          {
            required: true,
            message: "must select entity type",
            trigger: "change",
          },
        ],
        // channelType: [
        //   {
        //     required: true,
        //     message: "Must Select Channel Type",
        //     trigger: "change",
        //   },
        // ],
      },
      dialogVisible: false,
    };
  },
  watch: {
    isDialogVisible(newValue) {
      this.dialogVisible = newValue;
    },
  },
  computed: {
    allRegions() {
      if (this.lookups) {
        const mappedParentRegions = this.lookups?.regions.map((region) => {
          return { name: region.name, id: region.id };
        });
        const allRegions = [
          ...this.lookups.regionsChildren,
          ...mappedParentRegions,
        ];
        return allRegions;
      }
      return [];
    },
    ...mapState({
      lookups: (state) => state.Lookups.lookups,
    }),
  },
  methods: {
    // async getChannelTypes() {
    //   try {
    //     let channelTypesDetails = await retailersService.getChannelTypes(
    //       this.entityDetails.entity
    //     );
    //     console.log(channelTypesDetails, "<<<");
    //     // this.retailersDetails = channelTypesDetails;
    //   } catch (error) {
    //     this.$notify({
    //       title: this.$t("GlobalsErrorTitle"),
    //       message: extendedFunctions.errorMessageExtractor(error),
    //       type: "error",
    //     });
    //   }
    // },
    //  async getChannelTypes() {
    //   try {
    //     const {
    //       data: {
    //         data: { areas },
    //       },
    //     } = await regionsService.getAreasLookup({
    //       filter: { regionId: this.adObj.regions },
    //     });

    //     this.adObj.areasList = areas.map((a) => ({ id: a.key, name: a.value }));
    //     this.adObj.areas = areas.map((a) => a.key);
    //     this.showAreaFilters = false;
    //   } catch (err) {
    //     this.$notify({
    //       title: this.$t("GlobalsErrorTitle"),
    //       message: extendedFunctions.errorMessageExtractor(err),
    //       type: "error",
    //     });
    //   }
    // },
    async saveProfile() {
      const valid = await this.$refs.profileForm.validate();
      if (valid) this.$emit("entity-saved", this.entityDetails);
    },
    clearProfileDialog() {
      this.$emit("dialog-closed");
    },
  },
};
</script>
